import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "./style";

import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";

import { getAllOutletsFromCategoryAndLocationRequest } from "../../../api/brand.api";
import useCancellablePromise from "../../../api/cancelRequest";

import { useLocation } from "react-router-dom";
import { categoryList } from "../../../constants/categories";
import { ToastContext } from "../../../context/toastContext";
import { getValueFromCookie } from "../../../utils/cookies";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import SingleBrand from "./singleBrand";
import Button from "../../shared/button/button";
import { SearchContext } from "../../../context/searchContext";
import Loading from "../../shared/loading/loading";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as PreviousIcon } from "../../../assets/images/previous.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Brands = ({}) => {
  const observerTarget = useRef(null);

  const classes = useStyles();
  const { locationData: deliveryAddressLocation } = useContext(SearchContext);
  const [category, setCategory] = useState("");
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [afterKey, setAfterKey] = useState("");
  const [allRecordsFetched, setAllRecordsFetched] = useState(false);

  const dispatch = useContext(ToastContext);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const resetStates = () => {
    setBrands([]);
    setAfterKey("");
    setAllRecordsFetched(false);
  };

  useEffect(() => {
    let categoryName = query.get("c");
    if ((categoryName === null || categoryName === "") && locationData.pathname === "/") {
      categoryName = "Fashion"; // Assign default category
    }
    resetStates();
    if (categoryName) {
      getAllBrands(categoryName);
    }
  }, [locationData, deliveryAddressLocation]);

  console.log("locationData==>>", locationData);

  // The 'getAllBrands' function is where the API call is made to get the brands data
  const getAllBrands = async (categoryName) => {
    //let sc = JSON.parse(getValueFromCookie("search_context") || {});
    let sc;
    try {
      sc = JSON.parse(getValueFromCookie("search_context") || "{}");
    } catch (err) {
      console.error("Failed to parse search context from cookie:", err);
      sc = {}; // Fallback to an empty object
    }
    let after_key = afterKey;
    let all_records_fetched = allRecordsFetched;
    if (categoryName !== category) {
      resetStates();
      setCategory(categoryName);
      after_key = "";
      all_records_fetched = false;
    }

    const findCategory = categoryList.find(
      (item) => item.routeName === categoryName
    );
    if (findCategory && !all_records_fetched) {
      try {
        let reqParams = {
          domain: findCategory.domain,
          lat: sc.location.lat,
          lng: sc.location.lng,
          afterKey: after_key,
        };
        const data = await cancellablePromise(
          getAllOutletsFromCategoryAndLocationRequest(reqParams)
        );

        // Changes made here: Error Handling for non-JSON responses
        try {
          if (data.data && Array.isArray(data.data)) {
            const locationId = data?.afterKey?.location_id;
            if (locationId) {
		setAfterKey(locationId);
	    } else {
	      console.warn("location_id not found in API response afterKey", data.afterKey);
	    }
            //setAfterKey(data.afterKey.location_id);
            setBrands((oldBrands) => {
              // Initially this fn gets called twice, to avoid duplicate data,
              // add data only if existing brands are empty
              if (afterKey ? true : oldBrands.length === 0) {
                return [...oldBrands, ...data.data];
              }
              return oldBrands;
            });
          } else {
            throw new Error("Invalid data format"); // Added error handling for non-JSON data
          }
        } catch (parseError) {
          console.error("Error parsing response: ", parseError);
          // dispatch({
          //   type: toast_actions.ADD_TOAST,
          //   payload: {
          //     id: Math.floor(Math.random() * 100),
          //     type: toast_types.error,
          //     message: "Failed to load brands due to invalid response format.",
          //   },
          // });
        }

      } catch (err) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: err?.response?.data?.error?.message || "",
          },
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let categoryName = query.get("c");
    if ((categoryName === null || categoryName === "") && locationData.pathname === "/") {
      categoryName = "Fashion"; // Assign default category
    }
    getAllBrands(categoryName);
  }, [isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.brandContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h4">Stores Near You</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction="column"
        >
          <Grid container spacing={3} direction={"row"}>
            <>
              {brands.length > 0 ? (
                <>
                  {brands.map((item, ind) => (
                    <Grid
                      direction={"row"}
                      key={`sub-cat-item-${ind}`}
                      item
                      xs={10}
                      sm={10}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <SingleBrand data={item} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1">
                    No store available near you
                  </Typography>
                </Grid>
              )}
            </>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {isLoading && <Loading />}
            <div ref={observerTarget}></div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Brands;
